
const getDefaultState = () => {
    return {
        all: {
            list: null,
            amount: null
        },
    }
}

const state = getDefaultState()

const actions = {
    RESET_CLIENT_BONUS_STATE({ commit }) {
        commit('RESET_STATE')
    },
}

const getters = {
    ALL_CLIENT_BONUS: state => state.all,
}

const mutations = {
    RESET_STATE(state) {
        Object.assign(state, getDefaultState())
    },
    SET_ALL_CLIENT_BONUS: (state, data) => {
        state.all = data;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
};
