<template>
    <div v-if="topBanner && Object.keys(topBanner).length" class="header-plash constitution-top-bar">
        <div class="header-plash__wrapp constitution" :style="styleObject()">
            <div class="header-plash__item_middle">
                <img class="desktop" :src="getImgDesktop()" alt="icon">
                <img class="mobile" :src="getImgAdaptive()" style="height: 100%" alt="icon">
                <img class="tablet" :src="getImgTablet()" alt="icon">
            </div>
        </div>
        <a :title="topBanner['title']" :href="topBanner['linkUA']" target="_blank" class="header-plash__link"></a>
    </div>
</template>

<script>


    export default {
        props: ["layout"],
        components: {

        },
        data: () => ({
            //topBanner: null,
            adminApiUrl: process.env.VUE_APP_API_ADMIN_SERVER,
            isVisible: false
        }),
        created() {
            this.$store.dispatch('GET_TOP_BANNER');
        },
        computed: {
            topBanner: function () {
                return this.$store.getters.TOP_BANNER;
            },
        },
        mounted() {

        },

        methods: {
            styleObject: function () {
                return {
                    backgroundColor: '#' + this.topBanner?.color,
                    justifyContent: 'center'
                }
            },
            getImgDesktop() {
                return this.adminApiUrl + this.topBanner?.imgDesktop;
            },
            getImgTablet() {
                return this.adminApiUrl + this.topBanner?.imgTablet;
            },
            getImgAdaptive() {
                return this.adminApiUrl + this.topBanner?.imgAdaptive;
            },
        },
    }
</script>



