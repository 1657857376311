const state = {
    modules: [
        { key: '01eh74aqehaysn1dv1g6q786x0', id: 27, days: 90 }, //УПСК
        { key: '01epxznah8bfy66tbcxy525c6a', id: 20602681, days: 90 }, //УПСК
        { key: '01eh74aqehaysn1dv1g6q786x0',id: 27, days: 90 },// Альфа-Гарант
        { key: '01eh415dfptbz4dxzktgvpe3mp',id: 67, days: 31 },// Еталон
        { key: '01en5m73q4tks5d13v1n42ptjg',id: 85, days: 180 },// Кредо
        { key: '01eh4rcv416x8x2dt0pq4qy82p',id: 66, days: 90 },// Мотор-Гарант
        { key: '01f21s1ey8dwqa6mmvnqw4b05a',id: 75, days: 180 },// УСГ
        { key: '01eh4rcv4g32t3g2bgpjkpjtqv',id: 38, days: 30 },// Інго
        { key: '01eh415dfy7rhs7re5w2b9db4a',id: 73, days: 90 },// Брокбізнес
        { key: '01eh415dg0h46sb05bxhg3zg63',id: 47, days: 180 },// Універсальна
        { key: '01eh415dftv3g93zt78hga9eh9',id: 221, days: 180 },// Евроинс Украина
        { key: '01eh415dfrz8vt4q72wgssjnb4',id: 8, days: 90 },// ВУСО
        { key: '01eh4rcv44megz674np6nb1646',id: 52, days: 90 },// Омега
        { key: '01eh4rcv4jqdxvsywhyckvj0mz',id: 10, days: 30 },// АРКС
        { key: '01eh4rcv4ea0rrccgbpp9mq30a',id: 35, days: 180 },// Альфа Страхування
        { key: '01eh71n5cne2f01hw9cnsytg7q',id: 79, days: 30 },// Арсенал
        { key: '01eh4rcv4cgkvqqcqybbdx4ppp',id: 182, days: 90 },// Гардіан
        { key: '01eh415dfptbz4dxzktgvpe3mp',id: 67, days: 31 },// Еталон
        { key: '01eh4rcv3w1f2vzp9g524ag1sv',id: 69, days: 180 },// Мега-гарант
        { key: '01eh6z0rh40zdjmgecf4n4kskz',id: 254, days: 180 },// Обериг
        { key: '01eh74an1vkm3y0bt99b5n0ary',id: 91, days: 90 },// АСКА
        { key: '01epxznah0yt29w7zhy4d4ekdm',id: 30115243, days: 30 },// ТАС
        { key: '01epxznah3gspzeyye5z02mr0c',id: 22945712, days: 180 },// УТСК
        { key: '01f6pgzx3assznqxcr5aytqfag',id: 55, days: 180 },// УТСК
        { key: '01eh41bdgjc0emxtqyzetcq7jd',id: null, days: 180 },// Ван клик
        { key: '01f66yrmr0d98c1vsntyqzqqmr',id: 90, days: 180 },// ПЗУ
        { key: '01ey84devtscgqfd7x2c7prtma',id: 19350062, days: 180 },// Интер полис
    ],
    companyType:[
        { id: 1, type: "morphCompany", name:"ОСЦПВ" },
        { id: 2, type: "morphCompany", name:"Зелена Картка" },
        { id: 3, type: "morphCompany", name:"ДЦВ" },
        { id: 4, type: "morphCompany", name:"Туризм" },
        { id: 5, type: "morphCompany", name:"Автоюрист" },
        /*{ id: 5, type: "morphBusCompany", name:"Автобусы" },*/
    ]
};

const getters = {
    START_COMPANY_LIMIT: state => state.modules,
    START_COMPANY_TYPE: state => state.companyType
};

export default {
    state,
    getters
};

