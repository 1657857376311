const state = {
  types: [
    { name: 'Всі типи', id: null },
    { name: 'Без ОТК', id: 1 },
    { name: 'C ОТК', id: 2 },
  ],
};

const getters = {
    OTK_TYPES_LIST: state => state.types
};

export default {
    state,
    getters
};
