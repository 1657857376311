import {getMissions} from "@/api/bonusSystem/mission";

const state = {
    missions: [],
    tab: null,
    paginate: {
        perPage: 0,
        total: 0,
        page: 1
    },
    loading: false,
}

const mutations = {
    SET_LIST_MISSIONS(state, payload) {
        state.missions = payload.data
        state.paginate.total = payload.total
        state.paginate.perPage = payload.per_page
        state.paginate.page = payload.current_page
    },
    SET_LOADING(state, payload) {
        state.loading = payload
    },
    SET_TAB(state, payload) {
        state.tab = payload
    }
}

const actions = {
    getMissions({ state, commit }, data) {
        commit('SET_LOADING', true)
        if (data && data.tab) {
            commit('SET_TAB', data.tab ? data.tab : null)
        }
        let query = {...data, ...state.paginate.perPage, tab: state.tab}
        getMissions(query).then(response => {
            commit('SET_LIST_MISSIONS', response.data)
            commit('SET_LOADING', false)
        })
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
