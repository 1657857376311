<script>
export default {
    name: "userMenu",
    methods: {
        logout: function () {
            this.$store.dispatch('LOGOUT')
                .then(() => {
                    this.$router.push('/login')
                    this.$store.dispatch('RESET_CLIENT_BONUS_STATE');
                    this.$store.dispatch('RESET_TRANSACTION_STATE');
                    this.$store.dispatch('RESET_PAID_ORDERS_STATE');
                })
        },
    },
}
</script>

<template>
    <div class="m-2 text-left">
        <el-row>
            <el-col>
                <router-link :to="{name: 'profile'}" tag="div">
                    <div class="cursor-pointer">
                        <img src="~@/assets/images/icons/user-gray.svg" alt="">
                        Профіль
                    </div>
                </router-link>
            </el-col>
        </el-row>
        <el-row>
            <el-col>
                <div class="position-relative cursor-pointer pt-2" style="width: fit-content">
                    <div class="text-center soon">
                        <span class="label">Скоро</span>
                    </div>
                    <div>
                        <img src="~@/assets/images/icons/headset-gray.svg" alt="">
                        Персональний менеджер
                    </div>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col>
                <div class="position-relative cursor-pointer pt-2" style="width: fit-content">
                    <div class="text-center soon">
                        <span class="label">Скоро</span>
                    </div>
                    <div>
                        <img src="~@/assets/images/icons/handshake-heart-gray.svg" alt="">
                        Реферальна програма
                    </div>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col>
                <div @click="logout" class="cursor-pointer">
                    <img src="~@/assets/images/icons/power-off-gray.svg" alt="">
                    Вийти
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<style scoped>
.el-row {
    margin-bottom: 12px;
    &:last-child {
        margin-bottom: 0;
    }
}
.cursor-pointer {
    cursor: pointer
}
.soon {
    position: absolute;
    right: -2rem;
    top: 0;
    border: 1px solid #EF4137;
    border-radius: 3px;
    width: 44px;
    height: 16px;
    display: inline-grid;
    .label {
        color: #EF4137;
        font-weight: 800;
        font-size: 10px;
        line-height: 15.74px;
    }
}
</style>
