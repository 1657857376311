import penaltyApi from '@/api/penalty'

const namespaced = true

const state = {
    penalties: [],
    filters: [],
    total: 0,
}

const getters = {
    // penalties: state => type => {
    //     return state[type]
    // }
}

const mutations = {
    SET_PENALTIES(state, data) {
        state.penalties = data.data ?? []
        state.total = data.total
    },
    SET_FILTERS(state, data) {
        state.filters = data
    },
}

const actions = {
    async getPenalties ({ state, commit }, params) {
        console.log(params)
        await penaltyApi.getPenalties({...params, ...state.filters}).then(resp => {
            // commit('SET_PENALTIES', resp?.data?.data ?? [])
            commit('SET_PENALTIES', resp?.data)
        }).catch(err => {
            console.log(err);
        })
    }
}

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations
};
