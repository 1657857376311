    const state = {
        searchTourismParams: null,
        infoOffer: null,
        fullOrderInfo: null,
        infoUser: null,
        touristsListInfo: null,
        contractId: null,
    };
    const getters = {
        GET_SEARH_TOURISM_PARAMS: state => state.searchTourismParams,
        GET_TOURISM_INFO_OFFER: state => state.infoOffer,
        GET_TOURISM_INFO_USER: state => state.infoUser,
        GET_TOURISM_INFO_TOURISTSLIST: state => state.touristsListInfo,
        GET_TOURISM_FULL_ORDER: state => state.fullOrderInfo,
        GET_TOURISM_CONTRACT_ID: state => state.contractId,
    };
    const mutations = {
        SET_SEARCH_TOURISM_PARAMS: (state, data) => {
            state.searchTourismParams = data;
        },
        SET_TOURISM_INFO_OFFER: (state, data) => {
            state.infoOffer = data;
        },
        SET_TOURISM_FULL_ORDER: (state,orderId) => {
            state.fullOrderInfo = {...state.searchTourismParams,  ...state.infoOffer, ...{info:state.infoUser}, orderId};
        },
        SET_TOURISM_CONTRACT_ID: (state, data) => {
            state.contractId = data;
        },
        CLEAR_TOURISM_FULL_ORDER: (state) => {
            state.fullOrderInfo = null;
        },
        CLEAR_TOURISM_CONTRACT_ID: (state) => {
            state.contractId = null;
        },
        CLEAR_SEARCH_TOURISM_PARAMS: (state) => {
            state.searchTourismParams = null;
        },
        CLEAR_TOURISM_INFO_TOURISTSLIST: (state) => {
            state.touristsListInfo = null;
        },
        CLEAR_TOURISM_INFO_OFFER: (state) => {
            state.infoOffer = null;
        },
        CLEAR_TOURISM_INFO_USER: (state) => {
            state.infoUser = null;
        },
        SET_TOURISM_INFO_USER: (state, data) => {
            state.infoUser = data;
        },
        SET_TOURISM_INFO_TOURISTSLIST: (state, data) => {
            state.touristsListInfo = data;
        },
    };
    const actions = {
    };

    export default {
        state,
        getters,
        mutations,
        actions,
    };
