export default {
    data: function () {
        return {

        };
    },
    created: async function () {

    },
    computed: {
        linkStatusesMixin: function () {
            return this.$store.getters.LINK_STATUSES;
        },
        completedStatus: function () {
            return this.$_.findWhere(this.linkStatusesMixin, { type: 'completed'})?.id;
        },
        sentStatus: function () {
            return this.$_.findWhere(this.linkStatusesMixin, { type: 'sent' })?.id;
        },
        receivedStatus: function () {
            return this.$_.findWhere(this.linkStatusesMixin, { type: 'received'})?.id;
        },
        overdueStatus: function () {
            return this.$_.findWhere(this.linkStatusesMixin, { type: 'overdue'})?.id;
        },
        canceledStatus: function () {
            return this.$_.findWhere(this.linkStatusesMixin, { type: 'canceled'})?.id;
        },
    },
    methods:{
        showStatusId: function(link) {
            return this.$_.findWhere(this.linkStatusesMixin, {id: link.statusId})?.id;
        },
        showStatusName: function(link) {
            return this.$_.findWhere(this.linkStatusesMixin, {id: link.statusId})?.name;
        },
        getStatusClass: function(link) {
            return 'status-' + this.$_.findWhere(this.linkStatusesMixin, {id: link.statusId})?.type;
        },
        goToLinkInfo: function (link) {
            this.$router.push({name: 'links', params: {id: link.id}})
        },
    }
}
