const state = {
    statuses: [
        { name: 'Загальна', id: 1, type: "all" },
        { name: 'Поліси', id: 2, type: "orders" },
        { name: 'Об’єкти', id: 3, type: "objects", disabled: true },
        { name: 'Документи', id: 4, type: "documents", disabled: true },
    ],
    subStatuses: [
        { name: 'Всі', id: 0, type: "all" },
        { name: 'Тільки активні', id: 1, type: "active_orders" },
    ],
};

const getters = {
    CLIENTS_STATUSES: state => state.statuses,
    CLIENTS_ORDERS_SUB_STATUSES: state => state.subStatuses
};

export default {
    state,
    getters
};
