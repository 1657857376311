<template>
    <header :class="['header', {'active' : headerStatus}]">
        <bannerMain layout="profile" />
        <div class="container">
            <div class="row header__wrapp">
                <router-link to="/" class="header__logo">
                    <div v-if="checkSpecialLogo" class="logo special"></div>
                    <div v-else class="logo"></div>
<!--                    <span v-if="checkSpecialLogo" class="label label-special">beta</span>
                    <span v-else class="label">beta</span>-->
                </router-link>
                <div v-if="layout === 'login'" class="header__info">
                    <div class="header__nav">
<!--                        <check-penalties-button class="header__nav d-inline-flex mr-3">
                            <a href="#">Перевірити штрафи</a>
                        </check-penalties-button>-->
                        <router-link v-for="link in links" :key="link.url" tag="a" active-class="active" :to="link.url">
                            {{link.title}}
                        </router-link>
                    </div>
                </div>
                <a href="#" data-toggle="modal" data-target="#modalSearch" v-if="layout === 'profile'" class="header__create">
                   <span class="icon">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="9" width="2" height="20" fill="white"/>
                            <rect x="20" y="9" width="2" height="20" transform="rotate(90 20 9)" fill="white"/>
                        </svg>
                    </span>
                </a>
                <button class="header-mob-toggler" type="button" @click="headerStatus = !headerStatus">
                    <span></span>
                    <span></span>
                    <span></span>
                </button>

                <div class="header_maincontent">
                    <div v-if="layout !== 'login'" class="header__balance">
                        <div class="balance">
                            <el-col v-if="$store.getters.USER_INFO.levelId">
                                <bonus-popover></bonus-popover>
                            </el-col>
                            <el-col>
                                <finance-popover></finance-popover>
                            </el-col>
                        </div>
                    </div>
                </div>
                <div v-if="layout !== 'login'" class="header_maincontent header__user">
                    <div v-if="isMobile">
                        <user-header class="mb-5"></user-header>
                        <user-menu></user-menu>
                    </div>
                    <div v-else>
                        <user-popover></user-popover>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    import {userMixin} from '@/mixin';
    import bannerMain from '@/components/app/bannerMain';
    import modalReplenishment from '@/components/app/modals/modal-replenishment';
    import financePopover from "@/components/app/profile/financePopover.vue";
    import BonusPopover from "@/components/app/profile/bonusPopover.vue";
    import UserPopover from "@/components/app/profile/userPopover.vue";
    import UserMenu from "@/components/app/profile/userMenu.vue";
    import UserHeader from "@/components/app/profile/userHeader.vue";
    import displaySettings from "@/mixin/displaySettings";
    import CheckPenaltiesButton from "@/components/app/penalties/CheckPenaltiesButton.vue";
    import CheckPenaltiesModal from "@/components/app/penalties/CheckPenaltiesModal.vue";

    export default {
        props: ["layout"],
        mixins: [userMixin, displaySettings],
        components: {
            CheckPenaltiesModal,
            CheckPenaltiesButton,
            UserHeader,
            UserMenu,
            UserPopover,
            BonusPopover,
            modalReplenishment,
            bannerMain,
            financePopover
        },
        data: () => ({
            links: [
                {title: 'Увійти', url: '/login'},
                {title: 'Реєстрація', url: '/registration'}
            ],
            headerStatus: false
        }),

        serverPrefetch() {
            return this.fetchTransactions()
        },
        created() {
            this.$store.dispatch('GET_USERINFO');
        },
        computed: {
            checkSpecialLogo: function () {
                return this.$store.getters.USER?.id === 31 || this.$store.getters.USER?.user_parent_id === 31;
            },
            isLoggedIn: function () {
                return this.$store.getters.IS_LOGGED_IN;
            },
            isShowBonus: function () {
                return this.$store.getters.IS_SHOW_BONUS;
            },
            transactions() {
                return this.$store.state.transactionsStore.all.list;
            },
            amount() {
                return this.$store.state.transactionsStore.all.amount;
            },
            credit() {
                return this.$store.state.transactionsStore.all.creditLimit;
            },
            clientBonus() {
                return this.$store.state.clientBonusStore.all.amount
            },
        },
        mounted() {
            this.fetchTransactions();
            window.setInterval(() => {
                    this.fetchTransactions();
            }, 80000)
        },

        methods: {
            fetchTransactions() {
                if (this.isLoggedIn) {
                    return this.$store.dispatch('GET_ALL_TRANSACTIONS');
                }
            },
        },
    }
</script>
