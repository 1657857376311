<script>
import {userMixin} from "@/mixin";

export default {
    name: "userHeader",
    mixins: [userMixin],
}
</script>

<template>
    <div>
        <el-row>
            <el-col class="name">{{ getUserFullName() }}</el-col>
        </el-row>
        <el-row>
            <el-col class="params">
                <div class="param">{{ getUserEmail() }}</div>
                <div class="param text-center">id: {{ getUserId() }}</div>
            </el-col>
        </el-row>
    </div>
</template>

<style scoped>

</style>
