export function getLevels() {
    return axios({
        url: '/api/levels',
        method: 'get',
    });
}

export function getLevelProgress() {
    return axios({
        url: '/api/user-bonus-system-sum-sales',
        method: 'get',
    });
}
