const state = {
    userInfo: null,
};
const getters = {
    USER_INFO: state => state.userInfo,
};
const mutations = {
    SET_USERINFO: (state, payload) => {
        state.userInfo = payload;
    },
};
const actions = {
    GET_USERINFO({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            axios({ url: '/api/auth/user', method: 'GET', })
                .then(resp => {
                    const list = resp.data.data
                    commit('SET_USERINFO', list)
                    resolve(resp)
                })
                .catch(err => {
                    dispatch('LOGOUT');
                    reject(err)
                })
        })
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
