<script>
import ModalReplenishment from "@/components/app/modals/modal-replenishment.vue";

export default {
    name: "financePopover",
    components: {ModalReplenishment},
    data() {
        return {
            showPopover: false,
        }
    },
    computed: {
        isHideScore: function () {
            return this.$store.state.userSettings?.isHideScore || false;
        },
        amount() {
            return this.$store.state.transactionsStore.all.amount;
        },
        credit() {
            return this.$store.state.transactionsStore.all.creditLimit;
        },
    },
    methods: {
        showAmount(amount) {
            //TODO костыль, но пока вот так.
            if (amount != null) {
                let parts = amount.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");

                return parts.join(".");
            }
        },
        showCredit(credit) {
            //TODO костыль, но пока вот так.
            if (credit != null) {
                let parts = credit.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");

                return parts.join(".");
            } else {
                return null
            }
        },
        hideBalance() {
            this.$store.commit('SET_SETTING_HIDE_SCORE', ! this.isHideScore);
        },
        showReplenishment: function(){
            this.$root.$emit('showReplenishment')
        },
    },
}
</script>

<template>
    <div class="ml-2">
        <el-popover
            placement="bottom"
            width="300"
            trigger="click"
            v-model="showPopover"
        >
            <div class="balance-value" slot="reference">
                <el-row type="flex" align="middle">
                    <el-col :span="22">
                        <div class="balance-value__item balance-item">
                            <div class="value" slot="reference">
                                <div v-if="! this.isHideScore" class="d-flex">
                                    <img src="~@/assets/images/icons/hryvna-orange-circle.svg" alt="">
                                    <div class="ml-3">
                                        <span class="amount" v-if="! this.isHideScore">
                                            {{showAmount(amount) }}<!-- <span>грн</span>-->
                                        </span>
                                    </div>
                                </div>
                                <div v-else>
                                    <svg width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.038 3.16H0.87V0.96H15.038V3.16Z" fill="#24282C"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="balance-value__item credit-item" v-if="showCredit(credit)">
                            <div class="value">
                                <div v-if="! this.isHideScore" class="ml-1 mr-3">
                                    <img src="~@/assets/images/icons/mortgage.svg" alt="">
                                    <span class="amount ml-3" v-if="! this.isHideScore">
                                            {{ showCredit(credit) }} <span>грн</span>
                                        </span>
                                </div>
                                <div v-else>
                                    <svg width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.038 3.16H0.87V0.96H15.038V3.16Z" fill="#24282C"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="2">
                        <div class="m-1">
                            <i class="el-icon-arrow-up" v-if="showPopover"></i>
                            <i class="el-icon-arrow-down" v-else></i>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div class="m-3">
                <div class="title">
                    Фінанси
                </div>
                <el-row type="flex" align="middle">
                    <el-col>
                        <span class="text">
                            Ваш баланс
                        </span>
                    </el-col>
                    <el-col>
                        <div class="d-flex justify-content-end">
                            <span class="amount" v-if="! this.isHideScore">
                                {{showAmount(amount) }}
                            </span>
                            <img src="~@/assets/images/icons/hryvna-orange-circle.svg" alt="">
                        </div>
                    </el-col>
                </el-row>
                <el-row type="flex" align="middle" v-if="showCredit(credit)">
                    <el-col>
                        <span class="text">
                            Кредитний ліміт
                        </span>
                    </el-col>
                    <el-col>
                        <div class="d-flex justify-content-end">
                            <span class="amount ml-3" v-if="! this.isHideScore">
                                {{showCredit(credit) }}
                            </span>
                            <img src="~@/assets/images/icons/mortgage.svg" alt="">
                        </div>
                    </el-col>
                </el-row>
                <hr>
                <el-row>
                    <el-col>
                        <div @click="showReplenishment" class="cursor-pointer">
                            <img src="~@/assets/images/icons/wallet-gray.svg" alt="">
                            Поповнити
                        </div>
                        <modal-replenishment/>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <div @click="hideBalance" class="cursor-pointer">
                            <div v-if="this.isHideScore">
                                <img  src="~@/assets/images/icons/eye-gray.svg" alt="">
                                Показати баланс
                            </div>
                            <div v-else>
                                <img src="~@/assets/images/icons/eye-gray-slash.svg" alt="">
                                Сховати баланс
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <router-link :to="{name: 'score'}" tag="div">
                             <div class="cursor-pointer">
                                 <img src="~@/assets/images/icons/hryvna-gray-circle.svg" alt="">
                                 Історія транзакцій
                             </div>
                        </router-link>
                    </el-col>
                </el-row>
            </div>
        </el-popover>
    </div>
</template>

<style scoped>
.amount {
    font-size: 18px;
    color: #24282C;
    font-weight: bold;
}
.title {
    font-size: 22px;
    color: #24282C;
    margin-bottom: 16px;
}
.el-row {
    margin-bottom: 12px;
    &:last-child {
        margin-bottom: 0;
    }
}
.text {
    font-size: 13px;
    color: #24282C;
    font-weight: bold;
}
.cursor-pointer {
    cursor: pointer
}
</style>
