export function downloadGreenCardContract(id, typeId) {
    return axios({
        url: `/api/test/insurance/greencard/contract/take`,
        method: 'post',
        data: {
            contractId: id,
            orderType : typeId,
        },
    });
}
