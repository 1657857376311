export default {
    data() {
        return {
        };
    },
    created() {
        this.insuranceRegistrationType = this.$store.getters.INSURANCE_REGISTRATION_TYPE;
    },
    methods: {
        getPermanentRegistration: function () {
            return this.$_.findWhere(this.insuranceRegistrationType, { name: 'permanentRegistration'});
        },
        getTemporaryRegistration: function () {
            return this.$_.findWhere(this.insuranceRegistrationType, { name: 'temporaryRegistration'});
        },
        getTemporaryEntry: function () {
            return this.$_.findWhere(this.insuranceRegistrationType, { name: 'temporaryEntry'});
        }
    }
}
