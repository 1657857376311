const getDefaultState = () => {
    return {
        all: {
            list: null,
            amount: null
        },
    }
}

const state = getDefaultState()

const actions = {
    RESET_TRANSACTION_STATE({ commit }) {
        commit('RESET_STATE')
    },
    async GET_ALL_TRANSACTIONS({ commit }, params) {
        await axios({ url: '/api/transactions/all', method: 'POST' })
            .then(resp => {
                commit('SET_ALL_TRANSACTIONS', resp.data);
            })
            .catch(err => {
                console.log(err);
            })
    }
}

const getters = {
    ALL_TRANSACTIONS: state => state.all,
}

const mutations = {
    RESET_STATE(state) {
        Object.assign(state, getDefaultState())
    },
    SET_ALL_TRANSACTIONS: (state, data) => {
        state.all = data;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
};
