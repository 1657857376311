import createPersistedState from "vuex-persistedstate";

export default {
    /**
     *
     * @returns {{osagoOffers: null}}
     */
    state: () => ({
        companyId: null,
        companyName: null,
    }),

    getters: {
        GET_NEW_TARIFF: (state) => { return {companyId: state.companyId, companyName: state.companyName}},
    },

    mutations: {
        /**
         *
         * @param state
         * @param data
         * @constructor
         */
        SET_NEW_TARIFF:(state, data) => {
            state.companyId = data.companyId;
            state.companyName = data.companyName;
        },
    },
};
