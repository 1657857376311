export function countNotViewedNews() {
    return axios({
        url: `/api/new/count-not-viewed-news`,
        method: 'get',
    });
}

export function userViewedNews() {
    return axios({
        url: `/api/new/user-viewed-news`,
        method: 'get',
    });
}
