function getPenalties(params) {
    return axios({
        url: '/api/penalties',
        method: 'get',
        params: params
    });
}

function getPenalty(id) {
    return axios({
        url: `/api/penalties/${id}`
    })
}

function payPenalty(id) {
    return axios({
        url: `/api/penalties/${id}/pay`,
        method: 'get',
    })
}

function getReceipt(id) {
    return axios({
        url: `/api/penalties/${id}/receipt`,
        method: 'get',
    })
}

function countPenalty(params) {
    return axios({
        url: `/api/penalties/count`,
        method: 'get',
        params: params
    })
}

function exportPenalties(params = {}) {
    return axios({
        url: `/api/penalties/export`,
        method: 'get',
        params: params,
        responseType: 'blob',
    })
}

function checkPenaltiesByCar(params) {
    return axios({
        url: '/api/penalties/check-by-car',
        method: 'get',
        params: params,
    })
}

function checkPenaltiesByProtocol(params) {
    return axios({
        url: '/api/penalties/check-by-protocol',
        method: 'get',
        params: params,
    })
}

function checkPenaltiesByDriverLicense(params) {
    return axios({
        url: '/api/penalties/check-by-driver-license',
        method: 'get',
        params: params,
    })
}

function createPenaltyPayLink(id) {
    return axios({
        url: `/api/penalties/${id}/create-pay-link`,
        method: 'get',
    })
}

function getPenaltyForClient(protocol) {
    return axios({
        url: `/api/penalty-for-client/${protocol}`,
        method: 'get',
    });
}

export default {
    getPenalties,
    getPenalty,
    payPenalty,
    getReceipt,
    countPenalty,
    exportPenalties,
    checkPenaltiesByCar,
    checkPenaltiesByProtocol,
    checkPenaltiesByDriverLicense,
    createPenaltyPayLink,
    getPenaltyForClient
}
