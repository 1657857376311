const state = {
    statuses: [
        { name: '', id: 0 },
       /* { name: 'Заготовка под ордер', id: -1, type: "new" },*/
        { name: 'Чернетка', id: 1, type: "draft" },
        { name: 'Прострочений', id: 2, type: "overdue" },
        { name: 'Заявлений', id: 5, type: "declared" },
        { name: 'Укладено', id: 10, type: "concluded"},
        { name: 'Припинений', id: -10, type: "canceled"},
    ],
};

const getters = {
    ORDER_STATUSES: state => state.statuses
};

export default {
    state,
    getters
};
