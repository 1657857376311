const state = {
    types: [
        { name: 'Всі типи', id: null },
        { name: '1', id: 1 },
        { name: '2', id: 2 },
        { name: '3', id: 3 },
        { name: '4', id: 4 },
        { name: '5', id: 5 },
        { name: '6', id: 6 },
    ],
};

const getters = {
    ZONE_TYPES: state => state.types
};

export default {
    state,
    getters
};
