import {showOrder, list as listOrder, countNeedProlongation} from '../../api/order';
import {countNotViewedNews} from "@/api/news";


const getDefaultState = () => {
    return {
        paidOrders: null,
        order: null,
        countNeedProlongation: [],
    }
}

const state = getDefaultState()

const actions = {
    RESET_PAID_ORDERS_STATE({ commit }) {
        commit('RESET_STATE')
    },

    async GET_PAID_ORDERS({ commit }, params) {
        await listOrder(params).then(resp => {
                commit('SET_PAID_ORDERS', resp?.data?.data ?? []);
            })
            .catch(err => {
                console.log(err);
            })
    },

    async GET_ORDER({ commit }, orderId) {
        await showOrder(orderId)
            .then((response) => {;
                commit('SET_ORDER', response.data.data);
            })
            .catch(err => {
                console.log(err);
            })
    },

    GET_COUNT_NEED_PROLONGATION({ commit }) {
        countNeedProlongation().then(response => {
            commit('SET_COUNT_NEED_PROLONGATION', (response?.data?.data ?? 0));
        }).catch(err => {
            console.log(err);
        })
    },

}

const getters = {
    PAID_ORDERS: state => state.paidOrders,
    ORDER: state => state.order,
    COUNT_NEED_PROLONGATION: state => state.countNeedProlongation
}

const mutations = {
    RESET_STATE(state) {
        Object.assign(state, getDefaultState())
    },
    SET_PAID_ORDERS: (state, data) => {
        state.paidOrders = data;
    },
    SET_ORDER: (state, data) => {
        state.order = data;
    },
    SET_COUNT_NEED_PROLONGATION: (state, data) => {
        state.countNeedProlongation = data;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
};
