import { list as listCompanies } from '../../api/greenCardInsuranceCompany'

const getDefaultState = () => {
    return {
        greenCardCompanies: null,
        requestData: null,
        infoOffer: null,
        infoUser: null,
        fullOrderInfo: null,
        contractId: null,
        infoCar: null,
    }
}

const state = getDefaultState()

const actions = {
    async GET_GREENCARD_COMPANIES({ commit }, country, carType, userType, startDate, periodOption) {
        await listCompanies(country, carType, userType, startDate, periodOption)
            .then(resp => {
                commit('SET_GREENCARD_COMPANIES', resp?.data?.data ?? [])
            })
            .catch(err => {
                console.log(err)
            })
    },
}

const getters = {
    GREENCARD_COMPANIES: state => state.greenCardCompanies,
    GREENCARD_SEARCH_PARAMS: state => state.requestData,
    GREENCARD_INFO_OFFER: state => state.infoOffer,
    GREENCARD_INFO_USER: state => state.infoUser,
    GREENCARD_FULL_ORDER: state => state.fullOrderInfo,
    GREENCARD_CONTRACT_ID: state => state.contractId,
    GREENCARD_INFO_CAR: state => state.infoCar,
}

const mutations = {
    SET_GREENCARD_COMPANIES: (state, data) => {
      state.greenCardCompanies = data;
    },
    SET_GREENCARD_SEARCH_PARAMS: (state, data) => {
        state.requestData = data;
    },
    SET_GREENCARD_INFO_OFFER: (state, data) => {
        state.infoOffer = data;
    },
    SET_GREENCARD_INFO_USER: (state, data) => {
        state.infoUser = data;
    },
    SET_GREENCARD_INFO_CAR: (state, data) => {
        state.infoCar = data;
    },
    CLEAR_GREENCARD_INFO_CAR: (state) => {
        state.infoCar = null;
    },
    SET_GREENCARD_CONTRACT_ID: (state, data) => {
        state.contractId = data;
    },
    SET_GREENCARD_FULL_ORDER: (state,orderId) => {
        state.fullOrderInfo = {...state.requestData,  ...state.infoOffer, ...{info:state.infoUser}, orderId};
    },
    CLEAR_GREENCARD_FULL_ORDER: (state) => {
        state.fullOrderInfo = null;
    },
    SET_GREENCARD_CHANGE_ORDER: (state,data) => {
        state.fullOrderInfo = data
    },
}

export default {
    state,
    getters,
    mutations,
    actions
};
