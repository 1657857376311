const state = {
    modules: [
        { id: 4, key: 'ewa' },
        { id: 5, key: 'polis' },
        { id: 6, key: 'credo' },
        { id: 2, key: 'oneClick' },
    ],
};

const getters = {
    MODULES_LIST: state => state.modules
};

export default {
    state,
    getters
};

