/**
 * @param from
 * @param to
 * @param startDate
 * @param endDate
 * @param ticketNumber
 * @param ticketPrice
 *
 * @returns {*|AxiosPromise}
 */
export function list(from, to, startDate, endDate, ticketNumber, ticketPrice)
{
    return axios({
        url: '/api/' + process.env.VUE_APP_SERVER_MODE + '/insurance/bus/calculator',
        method: 'post',
        data: {
            fromCityId: from,
            toCityId: to,
            startDate: startDate,
            endDate: endDate,
            ticketNumber: ticketNumber,
            ticketPrice: ticketPrice,
        },
    });
}
