import { countNotViewedNews } from '@/api/news';


const state =  {
    count: 0,
};


const actions = {
     GET_COUNT_NOT_VIEWED_NEWS({ commit }) {
         countNotViewedNews().then(response => {
            commit('SET_COUNT_NOT_VIEWED_NEWS', (response?.data?.data ?? 0) > 9 ? 9 : response.data.data);
        }).catch(err => {
                console.log(err);
        })
    },
}

const getters = {
    COUNT_NOT_VIEWED_NEWS: state => state.count
}

const mutations = {
    SET_COUNT_NOT_VIEWED_NEWS: (state, data) => {
        state.count = data;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
};
