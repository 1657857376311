import store from "@/store";

const penalties = [
    {
        path: '/penalties',
        name: 'penalties',
        meta: {
            layout: 'profileLayout',
            requiresAuth: true,
            title: 'Штрафи'
        },
        beforeEnter: (to, from, next) => {
            if (store.getters.USER_INFO.isActivePenalty) {
                next();
            } else {
                next({name: 'prime'})
            }
        },
        component: () => import('../views/penalties/index.vue')
    },
    {
        path: '/penalties/:id',
        name: 'show-penalty',
        meta: {
            layout: 'profileLayout',
            requiresAuth: true,
            title: 'Інформація про штраф'
        },
        beforeEnter: (to, from, next) => {
            if (store.getters.USER_INFO.isActivePenalty) {
                next();
            } else {
                next({name: 'prime'})
            }
        },
        component: () => import('../views/penalties/show.vue')
    },
    {
        path: '/penalty/:docId',
        name: 'show-penalty-for-client',
        meta: {
            layout: 'loginLayout',
            title: 'Штрафи'
        },
        component: () => import('../components/app/penalties/PenaltyPage.vue')
    },
]

export default penalties;
