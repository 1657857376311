const getDefaultState = () => {
    return {
        topBanner: null,
        onmainBanner: null,
    }
}

const state = getDefaultState()

const actions = {
    GET_TOP_BANNER({ commit }) {
        axios({ url: '/api/banners/getTop', method: 'POST' })
            .then(resp => {
                commit('SET_TOP_BANNER', resp.data?.data);
            })
            .catch(err => {
                console.log(err);
            })
    },
    GET_ONMAIN_BANNER({ commit }) {
        axios({ url: '/api/banners/getOnMain', method: 'POST' })
            .then(resp => {
                commit('SET_ONMAIN_BANNER', resp.data?.data);
            })
            .catch(err => {
                console.log(err);
            })
    }
}

const getters = {
    TOP_BANNER: state => state.topBanner,
    ONMAIN_BANNER: state => state.onmainBanner,
}

const mutations = {
    SET_TOP_BANNER: (state, data) => {
        state.topBanner = data;
    },
    SET_ONMAIN_BANNER: (state, data) => {
        state.onmainBanner = data;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
};
