<template>
    <div class="login-layout">
        <headerCustom layout="login" />
        <main class="login">
            <router-view />
        </main>
        <footerMain/>
    </div>
</template>

<script>
import headerCustom from '@/components/app/headerCustom'
import footerMain from '@/components/app/footerMain'

export default {
    name: 'login-layout',
    components:{
        headerCustom, footerMain
    }
}
</script>
