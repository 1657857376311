const state = {
    patterns: {}
};

const getters = {
    AUTO_NUMBER_VALIDATION_LIST: state => state.patterns,
};

const actions = {
    async loadPatterns({ commit }) {
        const response = await axios.get('/api/auto-number-patterns');
        commit('SET_PATTERNS', response.data);
    }
};

const mutations = {
    SET_PATTERNS: (state, patterns) => {
        const groupedPatterns = patterns.reduce((acc, pattern) => {
            if (!acc[pattern.group]) {
                acc[pattern.group] = [];
            }
                acc[pattern.group].push(new RegExp(pattern.pattern));
            return acc;
        }, {});
        state.patterns = groupedPatterns;
    }
};


export default {
    state,
    getters,
    actions,
    mutations
};

