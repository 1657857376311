/**
 *
 * @param userId
 * @returns {*|AxiosPromise}
 */
export function getCompanySettingsList(params) {
    return axios({
        url: `/api/tariff-settings`,
        params: params,
        method: 'get',
    });
}

/**
 *
 * @param companyId
 * @param userId
 * @returns {*|AxiosPromise}
 */
export function showCompanySettings(companyId, userId) {
    return axios({
        url: `/api/company-settings/show`,
        method: 'get',
        data: { userId: userId, companyId: companyId},
    });
}

/**
 *
 * @param companyId
 * @param userId
 * @param percent
 * @param isActive
 * @returns {*|AxiosPromise}
 */
export function updateCompanySettings(companyId, userId, percent, isActive) {
    return axios({
        url: `/api/company-settings/update`,
        method: 'put',
        data: { userId: userId, companyId: companyId, percent: percent, isActive: isActive},
    });
}

export function list() {
    return axios({
        url: `/api/companies`,
        method: 'get',
    });
}




