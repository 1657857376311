export default {
    data() {
        return {
            companiesList: []
        };
    },
    computed: {
        customerPrivilegesList: function () {
            return this.$store.getters.CUSTOMER_PRIVILEGE;
        },
        customerTypesList: function () {
            return this.$store.getters.CUSTOMER_TYPES;
        },
        registrationTypes: function () {
            return this.$store.getters.INSURANCE_REGISTRATION_TYPE;
        }
    },
    methods: {
        isNaturalCustomerType: function (typeId) {
            return this.$_.findWhere(this.customerTypesList, { key: 'natural'})?.id === parseInt(typeId);
        },
        isDocumentERetiree: function (typeId) {
            return this.$_.findWhere(this.customerPrivilegesList, { name: 'e_retired'})?.id === parseInt(typeId);
        },
        isDocumentERetireeBy: function (typeId) {
            return this.$_.findWhere(this.customerPrivilegesList, { name: 'e_retired'})?.id === parseInt(typeId);
        },
        isPermanentRegistrationType: function (typeId) {
            return this.$_.findWhere(this.registrationTypes, { name: 'permanentRegistration'})?.id === parseInt(typeId);
        },
        isLegalCustomerType: function (typeId) {
            return this.$_.findWhere(this.customerTypesList, { key: 'legal'})?.id === parseInt(typeId);
        }
    }
}
