export default {
    /**
     *
     * @returns {{osagoOffers: null}}
     */
    state: () => ({
        clients_id: null,
    }),

    getters: {
        GET_CLIENT: state => state.clients_id,
    },

    mutations: {
       SET_CLIENT: (state, data) => {
            state.clients_id = data;
        },
    },

    actions: {
    },
};
