const state = {
    list: [
        { name: 'Паспорт', id: 'DOCUMENT_PASSPORT', uid: 1 },
        { name: 'ID карта', id: 'DOCUMENT_ID_CARD', uid: 3 },
        { name: 'Водійське посвідчення', id: 'DOCUMENT_DRIVERS_LICENSE',uid: 4 },

        { name: 'Посвідчення учасника ліквідації ядерних аварій', id: 'DOCUMENT_CHERNOBYL_VICTIM', key: 'e_retired', uid: 5 },
        { name: 'Посвідчення інваліда', id: 'DOCUMENT_HANDICAPPED', uid: 6 },
        { name: 'Пенсійне посвідчення', id: 'DOCUMENT_RETIREE', uid: 7 },
        { name: 'Електронне пенсійне посвідчення', id: 'DOCUMENT_E_RETIREE', uid: 8 },
        { name: 'Посвідчення учасник військових дій', id: 'DOCUMENT_VETERAN_CERTIFICATE', uid: 9 },

        { name: 'Закордонний паспорт', id: 'DOCUMENT_EXTERNAL_PASSPORT', uid: 2 },
        { name: 'Іноземний паспорт', id: 'DOCUMENT_FOREIGN_PASSPORT', uid: 10 },
        { name: 'Вид на проживання', id: 'DOCUMENT_RESIDENCE_PERMIT', uid: 11 },
        { name: 'ID-вид на проживання', id: 'DOCUMENT_ID_RESIDENCE_PERMIT', uid: 12 },
        { name: 'Регістраційний талон', id: 'DOCUMENT_REGISTRATION_CARD', uid: 13 },
        { name: 'Свідоцтво про народження', id: 'DOCUMENT_BIRTH_CERTIFICATE', uid: 14 },
    ],
    commonList: [
        { name: 'Паспорт', id: 'DOCUMENT_PASSPORT', uid: 1 },
        { name: 'ID карта', id: 'DOCUMENT_ID_CARD', uid: 3 },
        { name: 'Водійське посвідчення', id: 'DOCUMENT_DRIVERS_LICENSE',uid: 4 },
        { name: 'Закордонний паспорт', id: 'DOCUMENT_EXTERNAL_PASSPORT', uid: 2 },
        { name: 'Іноземний паспорт', id: 'DOCUMENT_FOREIGN_PASSPORT', uid: 10 },
        { name: 'Вид на проживання', id: 'DOCUMENT_RESIDENCE_PERMIT', uid: 11 },
        { name: 'ID-вид на проживання', id: 'DOCUMENT_ID_RESIDENCE_PERMIT', uid: 12 },
        { name: 'Регістраційний талон', id: 'DOCUMENT_REGISTRATION_CARD', uid: 13 },
        { name: 'Свідоцтво про народження', id: 'DOCUMENT_BIRTH_CERTIFICATE', uid: 14 },
    ],
};

const getters = {
    DOCUMENTS_TYPES: state => state.list,
    COMMON_DOCUMENTS_TYPES: state => state.commonList
};

export default {
    state,
    getters
};

