<template>
    <header :class="['header', {'active' : headerStatus}]">
        <bannerMain layout="profile" />
        <div class="container">
            <div class="row header__wrapp">
                <router-link to="/" class="header__logo">
                    <div v-if="checkSpecialLogo" class="logo special"></div>
                    <div v-else class="logo"></div>
                    <span v-if="checkSpecialLogo" class="label label-special">beta</span>
                    <span v-else class="label">beta</span>
                </router-link>

            </div>
        </div>
    </header>
</template>

<script>
    import {userMixin} from '@/mixin';
    import bannerMain from '@/components/app/bannerMain';
    import modalReplenishment from '@/components/app/modals/modal-replenishment';
    import financePopover from "@/components/app/profile/financePopover.vue";
    import BonusPopover from "@/components/app/profile/bonusPopover.vue";
    import UserPopover from "@/components/app/profile/userPopover.vue";
    import UserMenu from "@/components/app/profile/userMenu.vue";
    import UserHeader from "@/components/app/profile/userHeader.vue";
    import displaySettings from "@/mixin/displaySettings";

    export default {
        props: ["layout"],
        mixins: [userMixin, displaySettings],
        components: {
            UserHeader,
            UserMenu,
            UserPopover,
            BonusPopover,
            modalReplenishment,
            bannerMain,
            financePopover
        },
        data: () => ({
            links: [
                {title: 'Увійти', url: '/login'},
                {title: 'Реєстрація', url: '/registration'}
            ],
            headerStatus: false
        }),

        serverPrefetch() {
            return this.fetchTransactions()
        },
        created() {
            this.$store.dispatch('GET_USERINFO');
        },
        computed: {
            checkSpecialLogo: function () {
                return this.$store.getters.USER?.id === 31 || this.$store.getters.USER?.user_parent_id === 31;
            },
            isLoggedIn: function () {
                return this.$store.getters.IS_LOGGED_IN;
            },
            isShowBonus: function () {
                return this.$store.getters.IS_SHOW_BONUS;
            },
            transactions() {
                return this.$store.state.transactionsStore.all.list;
            },
            amount() {
                return this.$store.state.transactionsStore.all.amount;
            },
            credit() {
                return this.$store.state.transactionsStore.all.creditLimit;
            },
            clientBonus() {
                return this.$store.state.clientBonusStore.all.amount
            },
        },
        mounted() {
            this.fetchTransactions();
            window.setInterval(() => {
                    this.fetchTransactions();
            }, 80000)
        },

        methods: {
            fetchTransactions() {
                if (this.isLoggedIn) {
                    return this.$store.dispatch('GET_ALL_TRANSACTIONS');
                }
            },
        },
    }
</script>
