export default {
    computed: {
        userInfo:  function() {
            return this.$store.state.userInfo.userInfo
        },
        userTypes: function() {
            return this.$store.getters.USER_TYPES;
        }
    },
    serverPrefetch () {
        // возвращает Promise из действия, поэтому
        // компонент ждёт данные перед рендерингом
        return this.fetchUserInfo()
    },
    mounted () {
        // Если мы не сделали ещё это на сервере,
        // то получаем элемент (сначала показав текст загрузки)
        if (!this.userInfo) {
            this.fetchUserInfo()
        }
    },
    methods: {
        fetchUserInfo: function() {
            return this.$store.dispatch('GET_USERINFO');
        },
        getUserFullName: function() {
            return this.userInfo?.fullName;
        },
        userBusAccess: function() {
            return this.userInfo?.busInsurance;
        },
        getUserId: function() {
            return this.userInfo?.id;
        },
        getUserEmail: function() {
            return this.userInfo?.email;
        },
        getParentId: function() {
            return  this.userInfo?.parentId;
        },
        isPartner: function() {
            return this.$store.getters.IS_PARTNER_STATUS;
        },
        isLegalType: function() {
           return this.userInfo?.type_id == this.getLegalTypeId();
        },
        isLandingUserType: function(typeUserId = null) {
            return (typeUserId || this.userInfo.type_id ) == this.getLandingTypeId();
        },
        getLegalTypeId: function() {
            return this.$_.findWhere(this.userTypes, { key: 'legal'}).id;
        },
        getNaturalTypeId: function() {
            return this.$_.findWhere(this.userTypes, { key: 'natural'}).id;
        },
        getLandingTypeId: function() {
            return this.$_.findWhere(this.userTypes, { key: 'landing'}).id;
        },
    }
}
