<template>
    <div class="main-layout">
        <headerMain layout="main" />
        <main class="main">
            <div class="main-wrapp">
                <navbar/>
                <div class="right-section">
                    <router-view />
                </div>
            </div>
        </main>
        <modalSearch/>
        <footerMain/>
    </div>
</template>

<script>
import headerMain from '@/components/app/headerMain'
import navbar from '@/components/app/profile/navbar';
import footerMain from '@/components/app/footerMain'
import modalReplenishment from '@/components/app/modals/modal-replenishment'
import modalSearch from '@/components/app/modals/modal-search';


export default {
    name: 'main-layout',
    components:{
        navbar,headerMain, footerMain, modalSearch
    }
}
</script>
