<script>
import penalty from "@/components/app/penalties/mixin/penalty";
// import PayModal from "@/components/app/penalties/PayModal.vue";
import penaltyPayment from "@/components/app/penalties/mixin/penaltyPayment";

export default {
    name: "PenaltyListModal",
    // components: {PayModal},
    props: ['visible', 'penalties'],
    mixins: [penalty, penaltyPayment],
}
</script>

<template>
    <div class="penalty-list-modal">
        <el-dialog
            :visible.sync="dialogVisible"
            :fullscreen="isMobile"
            center
            :before-close="handleClose"
            :modal-append-to-body="false"
        >
            <!--        :before-close="handleClose"-->
            <div class="modal-search pb-3" v-if="penalties.length > 0">
                <div class="text-center pb-4">
                    <div>
                        <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.16283 71L48 2L87.8372 71H8.16283Z" stroke="#FFAD3D" stroke-width="2"/>
                            <line x1="48" y1="24" x2="48" y2="48" stroke="#FFAD3D" stroke-width="2"/>
                            <line x1="48" y1="54" x2="48" y2="60" stroke="#FFAD3D" stroke-width="2"/>
                        </svg>
                    </div>
                    <div class="title">
                        Знайдено штрафи
                    </div>
                </div>
                <!--            <el-row class="mr-5 ml-5 mt-3">-->
                <el-card v-for="item in penalties" :key="item.docId" class="mb-4">
                    <el-row type="flex">
                        <el-col>
                            <div class="license-plate">{{ item.licensePlate }}</div>
                            <div class="driver-license">{{ item.sprotocol }} {{ item.nprotocol }}</div>
                            <div class="driver-license">від {{ $moment(item.ddecision).format("DD.MM.YYYY") }}</div>
                            <div class="d-flex mr-2 mt-2">
                                <i class="icon eye"></i>
                                <router-link
                                    tag="span"
                                    :to="{name:'show-penalty-for-client', params: {docId: item.docId}}"
                                    class="cursor-pointer"
                                >
                                    <span @click="dialogVisible=false">Детальніше</span>
                                </router-link>
                            </div>
                        </el-col>
                        <el-col>
                            <div class="text-center price pt-2 pb-1">{{ item.sumPenalty }} грн</div>
                            <div
                                v-if="item.status !== 'ОЧІКУЄТЬСЯ ПІДТВЕРДЖЕННЯ ОПЛАТИ З ДЕРЖАВНОЇ КАЗНАЧЕЙСЬКОЇ СЛУЖБИ'">
                                <el-button
                                    type="warning"
                                    class="w-100"
                                    @click="payPenalty(item)"
                                >
                                    Сплатити
                                </el-button>
                            </div>
                            <div v-else>
                                <span style="word-break: auto-phrase">{{ item.status }}</span>
                            </div>
                        </el-col>
                    </el-row>
<!--                    <pay-modal :penalty="item"></pay-modal>-->
                </el-card>
                <!--            </el-row>-->
                <div class="pt-4 pr-2 pl-2">
                    *Натискаючи “Сплатити”, ви погоджуєтесь з <a href="/pdf/fines-contract.pdf" target="_blank">умовами оферти</a> надання інформації про
                    штрафи
                </div>
            </div>

            <div class="modal-search pb-3" v-else>
                <div class="text-center pb-4">
                    <div>
                        <svg width="100" height="100" viewBox="0 0 100 100" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <circle cx="50" cy="50" r="49.5" fill="white" stroke="#00B52C"/>
                            <line x1="29.3221" y1="49.6175" x2="48.3221" y2="65.6175" stroke="#00B52C"/>
                            <line x1="70.5907" y1="33.6763" x2="48.4134" y2="66.2812" stroke="#00B52C"/>
                        </svg>
                    </div>
                    <div class="title">
                        У вас все добре
                    </div>
                    <div>Штрафи ПДР сплачені або відсутні</div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<style scoped>
.penalty-list-modal {
    .el-dialog__body {
        color: #24282C;

        .title {
            font-size: 20px;
            font-weight: 700;
            line-height: 29.48px;
            text-align: center;
        }

        .el-button--warning {
            background-color: #FFAD3D;
        }

        .driver-license {
            color: #999DA6;
        }

        .license-plate {
            font-size: 15px;
        }

        .price {
            font-size: 20px;
        }
    }

    .is-always-shadow {
        box-shadow: 0px 4px 25px 0px #00000026;
    }
}
</style>
