const state = {
    list: [
        // { text: '', value: 'Нет привелегий', name: 'no', id: 1, document: ''}, // Нет привелегий
        { text: "Посвідчення пенсіонера", value: 'Пенсіонер', name: 'retired', id: 2, document: 'DOCUMENT_RETIREE', documentUid: 7 }, // Пенсионеры граждане Украины
        { text: "Електронне пенсійне посвідчення", value: 'Пенсіонер (електронне посвідчення)', name: 'e_retired', id: 6, document: 'DOCUMENT_E_RETIREE', documentUid: 8 }, // Пенсионеры граждане Украины, электронное свидетельство.
        { text: "Посвідчення учасника війни", value: 'Учасник війни', name: 'war', id: 3, document: 'DOCUMENT_VETERAN_CERTIFICATE', documentUid: 9 }, // Участники войны, которые определены законом
        { text: "Посвідчення інваліда", value: 'Інвалід II групи', name: 'invalid', id: 4, document: 'DOCUMENT_HANDICAPPED', documentUid: 6 }, // Инвалиды II группы
        { text: "Посвідчення чорнобильця", value: 'Чорнобилець', name: 'chernobyl', id: 5, document: 'DOCUMENT_CHERNOBYL_VICTIM', documentUid: 5 }, // Лица, пострадавшие вследствие Чернобыльской катастрофы, отнесены к I и II категории
    ],
};

const getters = {
    CUSTOMER_PRIVILEGE: state => state.list
};

export default {
    state,
    getters
};
