import {list as listCompanies} from "@/api/company";

export default {
    data() {
        return {
            companiesList: []
        };
    },
    created: async function () {
        let companiesList = await listCompanies();

        this.companiesList = companiesList?.data?.data;
    },
    computed: {
        modulesList: function () {
            return this.$store.getters.MODULES_LIST;
        },
    },
    methods: {
        disableWithoutIdentificationCode: function (companyId) {
            return process.env.VUE_APP_DISABLE_WITHOUT_IDENTIFICATION_CODE_COMPANY.indexOf(companyId) !== -1;
        },
        isEwa: function(moduleId) {
           return this.$_.findWhere(this.modulesList, { key: 'ewa'})?.id == moduleId;
        },
        isCredo: function(moduleId) {
            return this.$_.findWhere(this.modulesList, { key: 'credo'})?.id == moduleId;
        },
        canBeIssuedOnSameDay: function (companyId) {

            return process.env.VUE_APP_COMPANY_ID_DAY_TO_DAY.indexOf(companyId) !== -1;
        }
    },
}
