const state = {
    statuses: [
        { name: 'Всі', id: 0 },
        { name: 'Відправлено', id: 1, type: "sent" },
        { name: 'Пропозицію обрано', id: 2, type: "received" },
        { name: 'Поліс укладено', id: 5, type: "completed" },
        { name: 'Просрочено', id: 4, type: "overdue"},
    ],
};

const getters = {
    LINK_STATUSES: state => state.statuses
};

export default {
    state,
    getters
};
