/**
 * @param country
 * @param carType
 * @param userType
 * @param startDate
 * @param periodOption
 * @returns {*|AxiosPromise}
 */
export function list(paramsTourism)
{

    return axios({
        url: '/api/' + process.env.VUE_APP_SERVER_MODE + '/insurance/calculator/tourism',
        method: 'post',
        data: paramsTourism,
    });
}
