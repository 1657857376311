const state = {
    profileInfo: null,
};
const getters = {
    PROFILE_INFO: state => state.profileInfo,
};
const mutations = {
    SET_PROFILE_INFO: (state, payload) => {
        state.profileInfo = payload;
    },
    // ADD_PROFILE_INFO: (state, list,) => {
    //     state.profileInfo = list
    // },
};
const actions = {
    getProfileInfo({ commit }) {
        return new Promise((resolve, reject) => {
            axios({ url: '/api/user-info', method: 'GET', })
                .then(resp => {
                    const list = resp.data.data
                    commit('SET_PROFILE_INFO', list,)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    updateProfileInfo({ commit }, list) {
        return new Promise((resolve, reject) => {
            axios({ url: '/api/user-info',
                    data: list,
                    method: 'POST',
            }
            ).then(resp => {
                        const list = resp.data.data
                        // commit('ADD_PROFILE_INFO', list,)
                        resolve(resp)
                    }).catch(err => {
                        reject(err)
                    })
        })
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
