//TODO нужно хорошенько оптимизировать "хранилище"
const state = {
    status: '',
    isPartner: localStorage.getItem('isPartner') || 0,
    isShowBonus: localStorage.getItem('isShowBonus') || 0,
    token: localStorage.getItem('token') || '',
    info: JSON.parse(localStorage.getItem('info') || '{}'),
    keyUser: localStorage.getItem('keyUser'),
};
const getters = {
    IS_LOGGED_IN: state => !!state.token,
    AUTH_STATUS: state => state.status,
    IS_PARTNER_STATUS: state => !!+state.isPartner,
    IS_SHOW_BONUS: state => !!+state.isShowBonus,
    USER: state => state.info,
    KEY_USER: state => state.keyUser,
};
const mutations = {
    AUTH_REQUEST(state) {
        state.status = 'loading'
    },
    AUTH_SUCCESS(state, data) {
        state.status = 'success';
        state.isPartner = data.isPartner;
        state.isShowBonus = data.isShowBonus;
        state.token = data.token;
        state.info = data.info;
        state.keyUser = 'user_' +  data.info?.id;
    },
    AUTH_ERROR(state) {
        state.status = 'error'
    },
    LOGOUT(state) {
        state.status = '';
        state.token = '';
        state.partners = null;
        state.info = null;
        state.keyUser = null;
    },
};
const actions = {
    LOGIN({ commit }, user) {
        return new Promise((resolve, reject) => {
            commit('AUTH_REQUEST')
            axios({ url: '/api/auth/login', data: user, method: 'POST' })
                .then(resp => {
                    const token = resp.data.data.token;
                    const isPartner = resp.data.data.isPartner ? 1 : 0;
                    const isShowBonus = resp.data.data.isShowBonus ? 1 : 0;
                    const info = resp.data.data.info;

                    localStorage.setItem('token', token);
                    localStorage.setItem('isPartner', isPartner);
                    localStorage.setItem('isShowBonus', isShowBonus);
                    localStorage.setItem('keyUser', 'user_' + info.id);
                    localStorage.setItem('info', JSON.stringify(info));

                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                    commit('AUTH_SUCCESS',{token: token, isPartner: isPartner, isShowBonus: isShowBonus, info: info});
                    resolve(resp);
                })
                .catch(err => {
                    commit('AUTH_ERROR')
                    localStorage.removeItem('token');
                    localStorage.removeItem('isPartner');
                    localStorage.removeItem('info');

                    reject(err)
                })
        })
    },
    REGISTER({ commit }, user) {
        return new Promise((resolve, reject) => {
            commit('AUTH_REQUEST')
            axios({ url: '/api/auth/register', data: user, method: 'POST' })
                .then(resp => {
                    const token = resp.data.data.token;
                    const isPartner = resp.data.data.isPartner ? 1 : 0;
                    const isShowBonus =  0;
                    const info = resp.data.data.info;
                    localStorage.setItem('token', token);
                    localStorage.setItem('isPartner', isPartner);
                    localStorage.setItem('isShowBonus', isShowBonus);
                    localStorage.setItem('keyUser', 'user_' +  info.id);
                    localStorage.setItem('info', JSON.stringify(info));

                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                    commit('AUTH_SUCCESS',{token: token, isPartner: isPartner, info: info});
                    resolve(resp);
                })
                .catch(err => {
                    commit('AUTH_ERROR', err)
                    localStorage.removeItem('token')
                    localStorage.removeItem('isPartner')
                    localStorage.removeItem('info')

                    reject(err)
                })
        })
    },
    LOGOUT({ commit }) {
        return new Promise((resolve, reject) => {
            commit('LOGOUT')
            localStorage.removeItem('token');
            localStorage.removeItem('isPartner');
            localStorage.removeItem('isShowBonus');
            localStorage.removeItem('info');
            localStorage.removeItem('keyUser');
            delete axios.defaults.headers.common['Authorization']
            resolve();
        })
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
