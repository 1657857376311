import {getActivityTypes} from "@/api/activityTypes";

const state = {
    list: []
}

const mutations = {
    SET_ACTIVITY_TYPES: (state, payload) => {
        state.list = payload
    }
}

const actions = {
    getActivityTypes({ commit }) {
        getActivityTypes().then(response => {
            commit('SET_ACTIVITY_TYPES', response.data)
        })
    }
}

export default {
    state,
    // getters
    mutations,
    actions,
};
