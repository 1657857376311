export default {
    data() {
        return {
        };
    },
    created: async function () {
    },
    computed: {
    },
    methods: {
        getPeriodNew(){
            this.checkOtkDateError = false;
            this.registrationDateError = false;
            let currentValue = this.registrationDate ? this.registrationDate : this.otkDate;

            let currentMoment = moment(currentValue, 'DD/MM/YYYY').startOf('day'); // Установить начало дня
            let startMoment = moment(this.startOrderDate, 'DD/MM/YYYY').startOf('day'); // Установить начало дня
            startMoment.subtract(1, 'days'); //фикс даты начала

            let diffYears = currentMoment.diff(startMoment, 'years', true);
            let diffMonths = currentMoment.diff(startMoment, 'months', true);
            let diffDays = currentMoment.add(1, 'days').diff(startMoment, 'days', true) ;

            if (diffYears >= 1) {
                let count = Math.floor(diffYears); // Округлить до ближайшего меньшего года
                this.periodOption = this.periodOptions.find(option => option.id === 12);
                if (count >= 2) {
                    //this.calculatedPeriod = count + ' роки';
                } else if (count >= 5) {
                    //this.calculatedPeriod = count + ' років';
                } else {
                    //this.calculatedPeriod = count + ' рік';
                }
                this.endOrderDate = startMoment.add(1, 'years').toDate();
            } else if (diffMonths >= 1) {
                let count = Math.floor(diffMonths); // Округлить до ближайшего меньшего месяца
                this.periodOption = this.periodOptions.find(option => option.id === count);
                this.endOrderDate = startMoment.add(count, 'months').toDate();
                if (count < 5 && count > 1) {
                    //this.calculatedPeriod = count + ' місяці';
                } else if (count >= 5) {
                    //this.calculatedPeriod = count + ' місяців';
                } else {
                    //this.calculatedPeriod = count + ' місяць';
                }
            } else {
                let count = Math.floor(diffDays); // Округлить до ближайшего меньшего дня
                if (count >= 15) {
                    //this.calculatedPeriod = '15 днів';
                    this.endOrderDate = startMoment.add(15, 'days').toDate();
                    this.periodOption = this.periodOptions.find(option => option.id === 15);
                } else {
                    this.periodOption = this.periodOptions.find(option => option.id === 12);
                    //this.calculatedPeriod = count + ' рік';
                    setTimeout(() => {
                        this.otkDate = null;
                        this.registrationDate = null;
                        this.$nextTick(() => {
                            this.checkOtkDateError = true;
                            this.registrationDateError = true;
                        });
                    }, 100);
                }
            }
        },
    },
}
