import {getLevels} from "@/api/bonusSystem/level";

const state = {
    levels: [],
    loading: false,
}

const mutations = {

}

const actions = {
    getLevels({ state }) {
        state.loading = true
        getLevels().then(response => {
            state.levels = response.data
            state.loading = false
        })
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
