/**
 * @param country
 * @param carType
 * @param userType
 * @param startDate
 * @param periodOption
 * @returns {*|AxiosPromise}
 */
export function list(country, carType, userType, startDate, periodOption)
{

    return axios({
        url: '/api/' + process.env.VUE_APP_SERVER_MODE + '/insurance/greencard/calculator',
        method: 'post',
        data: {
            country: country,
            carType: carType,
            userType: userType,
            startDate: startDate,
            periodOption: periodOption
        },
    });
}
