export default {
    state: () => ({
        all: null,
    }),

    getters: {
        ALL_PARTNERS: state => state.all,
    },

    mutations: {
        SET_ALL_PARTNERS:(state,data) => {
            state.all = data;
        }
    },

    actions: {
        /**
         *
         * @param commit
         * @param params
         * @returns {Promise<void>}
         * @constructor
         */
        async GET_ALL_PARTNERS({ commit }, params) {
            await axios({ url: '/api/partners', method: 'GET'})
                .then(response => {
                    commit('SET_ALL_PARTNERS', response.data.data);
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }
};
