import { list as listCompanies } from '../../api/busInsuranceCompany'

const getDefaultState = () => {
    return {
        busCompanies: null,
        requestData: null,
        infoOffer: null,
        infoUser: null,
        fullOrderInfo: null,
        contractId: null,
    }
}

const state = getDefaultState()

const actions = {
    async GET_BUS_COMPANIES({ commit }, from, to, startDate, endDate, ticketNumber, ticketPrice) {
        await listCompanies(from, to, startDate, endDate, ticketNumber, ticketPrice)
            .then(resp => {
                commit('SET_BUS_COMPANIES', resp?.data?.data ?? [])
            })
            .catch(err => {
                console.log(err)
            })
    },
}

const getters = {
    BUS_COMPANIES: state => state.busCompanies,
    BUS_SEARCH_PARAMS: state => state.requestData,
    BUS_INFO_OFFER: state => state.infoOffer,
    BUS_INFO_USER: state => state.infoUser,
    BUS_FULL_ORDER: state => state.fullOrderInfo,
    BUS_CONTRACT_ID: state => state.contractId,
}

const mutations = {
    SET_BUS_COMPANIES: (state, data) => {
      state.busCompanies = data;
    },
    SET_BUS_SEARCH_PARAMS: (state, data) => {
        state.requestData = data;
    },
    SET_BUS_INFO_OFFER: (state, data) => {
        state.infoOffer = data;
    },
    SET_BUS_INFO_USER: (state, data) => {
        state.infoUser = data;
    },
    SET_BUS_CONTRACT_ID: (state, data) => {
        state.contractId = data;
    },
    SET_BUS_FULL_ORDER: (state,orderId) => {
        state.fullOrderInfo = {...state.requestData, ...state.infoOffer, ...{info:state.infoUser}, orderId}
    },
    CLEAR_BUS_FULL_ORDER: (state) => {
        state.fullOrderInfo = null;
    },
    SET_BUS_CHANGE_ORDER: (state,data) => {
        state.fullOrderInfo = data
    },
}

export default {
    state,
    getters,
    mutations,
    actions
};
