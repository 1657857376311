<template>
    <div id="app">
        <component :is="layout">
            <router-view/>
        </component>
    </div>
</template>

<script>
import loginLayout from '@/layouts/loginLayout'
import mainLayout from '@/layouts/mainLayout'
import profileLayout from '@/layouts/profileLayout'
import customLayout from '@/layouts/customLayout'
import {mapActions} from "vuex";

export default {
    computed: {
        layout() {
            return (this.$route.meta.layout || 'main-layout')
        }
    },
    methods: {
        ...mapActions([
            'checkIsMobile'
        ])
    },
    components: {
        loginLayout, mainLayout, profileLayout, customLayout
    },
    mounted() {
        /*if (process.env.VUE_APP_SERVER_MODE !== 'test') { // Не понятно зачем... и не работает, пока отключу, чтоб не висели запросы
          let recaptchaScript = document.createElement('script')
          recaptchaScript.innerHTML = '!function(){function t(t,e){return function(){window.carrotquestasync.push(t,arguments)}}if("undefined"==typeof carrotquest){var e=document.createElement("script");e.type="text/javascript",e.async=!0,e.src="//cdn.carrotquest.app/api.min.js",document.getElementsByTagName("head")[0].appendChild(e),window.carrotquest={},window.carrotquestasync=[],carrotquest.settings={};for(var n=["connect","track","identify","auth","oth","onReady","addCallback","removeCallback","trackMessageInteraction"],a=0;a<n.length;a++)carrotquest[n[a]]=t(n[a])}}(),carrotquest.connect("42196-079506a2f142f4b8f5cf723a22");'
          document.head.appendChild(recaptchaScript);
      }*/
    },
    created: function () {
        window.addEventListener("resize", this.checkIsMobile);
        this.checkIsMobile();

        this.$http.interceptors.response.use(undefined, function (err) {
            return new Promise(function (resolve, reject) {
                if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
                    this.$store.dispatch("LOGOUT")
                }
                throw err;
            });
        });
    }
}
</script>

<style lang="scss">
@import '~bootstrap/dist/css/bootstrap.min.css';
@import 'assets/sass/app/app.scss';

</style>
