<script>

import penalty from "@/components/app/penalties/mixin/penalty";

var IconComponent = {
    template: `
        <div class="icon">
            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.21994 4.54994H9.44992L7.29993 6.71996C7.24729 6.77036 7.2054 6.83091 7.17679 6.89793C7.14817 6.96496 7.13342 7.03709 7.13342 7.10997C7.13342 7.18285 7.14817 7.25495 7.17679 7.32198C7.2054 7.389 7.24729 7.44955 7.29993 7.49995C7.34977 7.55288 7.41037 7.59455 7.47763 7.62215C7.54489 7.64974 7.61729 7.66263 7.68994 7.65996C7.76236 7.6608 7.83418 7.64707 7.90118 7.61958C7.96818 7.5921 8.02897 7.55141 8.07993 7.49995L11.1699 4.40996C11.2713 4.30546 11.3281 4.16556 11.3281 4.01994C11.3281 3.87433 11.2713 3.73446 11.1699 3.62996L8.07993 0.539963C7.97649 0.436528 7.83622 0.378418 7.68994 0.378418C7.54366 0.378418 7.40336 0.436528 7.29993 0.539963C7.19649 0.643397 7.1384 0.783684 7.1384 0.929962C7.1384 1.07624 7.19649 1.21653 7.29993 1.31996L9.44992 3.46996H1.21994C1.07407 3.46996 0.934169 3.52791 0.831024 3.63106C0.727879 3.7342 0.669922 3.87407 0.669922 4.01994C0.675103 4.16232 0.735332 4.29715 0.837921 4.39601C0.94051 4.49487 1.07747 4.55004 1.21994 4.54994Z" fill="#009BEB"/>
            </svg>
        </div>
    `
}

export default {
    name: "CheckPenaltiesModal",
    props: ['visible'],
    components: {IconComponent},
    mixins: [penalty],
    methods: {
        changeModal(modal) {
            this.$emit('change-modal', modal)
        }
    }
}
</script>

<template>
<div>
    <el-dialog
        :visible.sync="dialogVisible"
        :modal-append-to-body="false"
        center
        :fullscreen="isMobile"
    >
        <div class="modal-search pb-5">
            <div class="modal-search__heading">Перевірити штрафи</div>
            <div class="modal-search__descr">Виберіть тип перевірки:</div>
            <div class="modal-search__list">
                <div class="modal-search__item" @click="changeModal('CheckPenaltiesByCarModal')">
                    <div class="preview">
                        <img src="/images/modal-search-item1.svg" alt="">
                    </div>
                    <span>За ТЗ</span>
                    <IconComponent/>
                </div>
                <div class="modal-search__item" @click="changeModal('CheckPenaltiesByProtocolModal')">
                     <div class="preview">
                        <img src="/images/modal-search-item2.svg" alt="">
                     </div>
                    <span>За постановою</span>
                    <IconComponent/>
                </div>
                <div class="modal-search__item" @click="changeModal('CheckPenaltiesByDriverLicenceModal')">
                    <div class="preview">
                        <img src="/images/modal-search-item3.svg" alt="">
                    </div>
                    <span>За водійським</span>
                    <IconComponent/>
                </div>
            </div>
        </div>
    </el-dialog>
</div>
</template>

<style scoped>
* >>> .el-dialog__body {
    color: black;
}
</style>
