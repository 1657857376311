const state = {
    list: [
        { value: 'Фізична особа', id: 0, key: 'natural' },
        { value: 'Юридична особа', id: 1, key: 'legal' },
        { value: 'Landing', id: 2, key: 'landing' },
    ],
};

const getters = {
    USER_TYPES: state => state.list
};

export default {
    state,
    getters
};
