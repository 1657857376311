<script>
import {downloadPdf} from "@/api/order";
import FileDownload from "js-file-download";

export default {
    name: "bonusPopover",
    data() {
        return {
            showPopover: false,
        }
    },
    computed: {
        isHideScore: function () {
            return this.$store.state.userSettings?.isHideScore || false;
        },
    },
    methods: {
        async downloadPdf() {
            let pdf = await downloadPdf('/api/bonus-system/rules');

            if (pdf?.data) {
                FileDownload(pdf?.data, 'rules.pdf');
            }
        },
        hideBalance() {
            this.$store.commit('SET_SETTING_HIDE_SCORE', ! this.isHideScore);
        },
    }
}
</script>

<template>
<div>
    <el-popover
        placement="bottom"
        width="300"
        trigger="click"
        v-model="showPopover"
    >
        <div class="balance-value line-right w-100 text-center" slot="reference">
            <el-row type="flex" align="middle">
                <el-col :span="23">
                    <div class="balance-value__item balance-item">
                        <div class="value">
                            <div v-if="! this.isHideScore" class="d-flex">
<!--                                <img src="~@/assets/images/icons/ukasko-bonus.svg" alt="">-->
                                <img src="~@/assets/images/bonus-system/ukasko-bonus.svg" alt="">
                                <div class="ml-3">
                                            <span class="amount" v-if="! this.isHideScore">
                                                {{ $store.getters.USER_INFO.bonusSystemBalance }}
                                            </span>
                                </div>
                            </div>
                            <div v-else>
                                <svg width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.038 3.16H0.87V0.96H15.038V3.16Z" fill="#24282C"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="1">
                    <i class="el-icon-arrow-up" v-if="showPopover"></i>
                    <i class="el-icon-arrow-down" v-else></i>
                </el-col>
            </el-row>
        </div>
        <div class="m-3">
            <div class="title">
                Бонуси
            </div>
            <el-row type="flex" align="middle">
                <el-col>
                    <span class="text">
                        <b>Ваш баланс</b>
                    </span>
                </el-col>
                <el-col>
                    <img src="~@/assets/images/icons/ukasko-bonus.svg" alt="">
                    <span class="amount" v-if="! this.isHideScore">
                        {{ $store.getters.USER_INFO.bonusSystemBalance }}
                    </span>
                </el-col>
            </el-row>
            <hr>
            <el-row>
                <el-col>
                    <div class="cursor-pointer" @click="$router.push({name: 'bonusSystem'})">
                        <img src="~@/assets/images/icons/gift-gray.svg" alt="">
                        Бонусна система
                    </div>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <div @click="hideBalance" class="cursor-pointer">
                        <img v-if="this.isHideScore" src="~@/assets/images/icons/eye-gray.svg" alt="">
                        <img v-else src="~@/assets/images/icons/eye-gray-slash.svg" alt="">
                        Сховати баланс
                    </div>
                </el-col>
            </el-row>
            <hr>
            <span>
              Бонусні бали - віртуальні облікові одиниці, які нараховуються при виконанні певних дій та якими можна розраховуватись виключно на платформі ukasko.connect. З повним переліком правил бонусної системи можна ознайомитись за <a
                href="#" @click="downloadPdf">посиланням</a>
            </span>
        </div>
    </el-popover>
</div>
</template>

<style scoped>
.amount {
    font-size: 18px;
    color: #24282C;
    font-weight: bold;
}
.title {
    font-size: 22px;
    color: #24282C;
    margin-bottom: 16px;
}
.el-row {
    margin-bottom: 12px;
    &:last-child {
        margin-bottom: 0;
    }
}
.text {
    font-size: 13px;
    color: #24282C;
    font-weight: bold;
}
.cursor-pointer {
    cursor: pointer
}
</style>
