export default {
    methods: {
        getToday: function(format) {
            return moment().format(format);
        },
        getYesterday: function(format) {
            return moment().subtract(1, 'days').format(format);
        },
        getStartOfWeek: function(format) {
            return moment().clone().startOf('week').add(1, 'day').format(format);
        },
        getEndOfWeek: function(format) {
            return moment().clone().endOf('week').add(1, 'day').format(format);
        },
        getStartOfMonth: function(format) {
            return moment().clone().startOf('month').format(format);
        },
        getEndOfMonth: function(format) {
            return moment().clone().endOf('month').format(format);
        }
    }
}
