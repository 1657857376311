<script>
import penaltyApi from "@/api/penalty"
import {autoNumberValidate} from "@/mixin";
import {required} from "vuelidate/lib/validators";
import penalty from "@/components/app/penalties/mixin/penalty";

export default {
    name: "CheckPenaltiesByCarModal",
    props: ['visible', 'penalties'],
    data() {
        return {
            document: null,
            licensePlate: null,
            submitCheck: false,
            error: false,
        };
    },
    mixins: [
        autoNumberValidate, penalty
    ],
    validations: {
        licensePlate: {
            required,
            isObjectValid: function (value) {
                return this.validateCarNumber(value)
            }
        },
        document: {
            required,
            isObjectValid: function (value) {
                let pattern = /^[A-ZА-ЯІЇЄ]{3}[0-9]{6}$/;
                return pattern.test(value.toUpperCase());
            }
        }
    },
    methods: {
        checkPenalties() {
            this.submitCheck = true;

            if (this.$v.$invalid) {
                return false;
            }

            penaltyApi.checkPenaltiesByCar({
                document: this.document,
                licensePlate: this.licensePlate
            }).then(response => {
                this.penaltyList = response.data;
                this.changeModalTo('PenaltyListModal');
            }).catch(error => {
                if (error.response.data.error.Message === 'Document not found') {
                    this.error = true;
                }
            })
        }
    }
}
</script>

<template>
    <div>
        <el-dialog
            :visible.sync="dialogVisible"
            :fullscreen="isMobile"
            center
            :before-close="handleClose"
            :modal-append-to-body="false"
        >
            <div class="modal-search pb-5">
                <div class="modal-search__heading">
                    Перевірка штрафів
                    <br> за транспортним засобом
                    <img src="images/modal-search-item1.svg" alt="">
                </div>

                <el-row class="mr-5 ml-5 mt-3">
                    <el-col class="form-group">
                        <div class="text-center">
                            <small class="error" v-if="error">Документ не знайдено перевірте правильність даних</small>
                        </div>
                        <div class="label">
                            Серія та номер свідоцтва про реєстрацію ТЗ
                        </div>
                        <el-input
                            id="doc-car"
                            placeholder="РРХ123456"
                            v-model="document"
                        ></el-input>
                        <small class="error" v-if="!$v.document.required && submitCheck">Введіть номер</small>
                        <small class="error" v-else-if="$v.document.$invalid && submitCheck">Неправильно введенні
                            данні</small>
                    </el-col>
                    <el-col class="form-group">
                        <div class="label">Номерний знак</div>
                        <el-input
                            placeholder="Наприклад: АХ1111ВС"
                            v-model="licensePlate"
                        ></el-input>
                        <small class="error" v-if="!$v.licensePlate.required && submitCheck">Введіть номер</small>
                        <small class="error" v-else-if="$v.licensePlate.$invalid && submitCheck">Неправильний
                            номер</small>
                    </el-col>
                    <el-col class="text-center">
                        <button
                            @click="checkPenalties"
                            class="btn btn-primary p-2 w-50"
                        >
                            Перевірити
                        </button>
                    </el-col>
                    <el-col>
                        <div class="pt-4 pr-2 pl-2 text-left">
                            *Натискаючи “Перевірити”, ви погоджуєтесь з <a href="/pdf/fines-contract.pdf"
                                                                           target="_blank" style="width:unset">умовами оферти</a> надання
                            інформації про
                            штрафи
                        </div>
                    </el-col>
                </el-row>
            </div>
        </el-dialog>
    </div>
</template>

<style scoped>
* >>> .el-dialog__body {
    color: black;
}

* >>> .el-row {
    .el-col {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.label {
    font-size: 18px;
    font-weight: 700;
}
</style>
