import penaltyApi from "@/api/penalty";
import displaySettings from "@/mixin/displaySettings";

export default {
    mixins: [displaySettings],
    computed: {
        dialogVisible: {
            get() {
                return this.visible;
            },
            set(value) {
                this.$emit('update:visible', value);
            }
        },
        penaltyList: {
            get() {
                return this.penalties;
            },
            set(value) {
                this.$emit('update:penalties', value);
            }
        },
    },
    watch: {
        document(value) {
            this.document = value.toUpperCase();
        },
        licensePlate(value) {
            this.licensePlate = value.toUpperCase();
        },
    },
    methods: {
        handleClose(done) {
            this.$emit('change-modal', 'CheckPenaltiesModal');
            done();
        },
        changeModalTo(modal) {
            this.$emit('change-modal', modal)
        },
    }
}
