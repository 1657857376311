<template>
    <footer>
   <div class="container">
      <div class="row">
         <div v-if="checkSpecialLogo" class="footer-logo col-12">
            <div class="logo"> <!--<span class="label">beta</span>--></div>
            <p>Працює на платформі<br> онлайн-страхування</p>
         </div>
          <div v-else class="footer-logo col-12">
              <div class="logo"> <!--<span class="label">beta</span>--></div>
              <p>Платформа<br> онлайн-страхування</p>
          </div>
         <div class="footer-socials col-12" v-show="$route.name !== 'promo'">
            <div v-if="! isPartner()" class="socials">
                <a href="https://ukasko.ua/go?to=https%3A%2F%2Fwww.facebook.com%2Fukasko.ua%2F" target="_blank" rel="nofollow"><i class="facebook"></i></a>
                <a href="https://ukasko.ua/go?to=https%3A%2F%2Fwww.instagram.com%2Fukasko_ua%2F" target="_blank" rel="nofollow"><i class="instagram"></i></a>
                <a href="https://ukasko.ua/go?to=https%3A%2F%2Fwww.linkedin.com%2Forganization-guest%2Fcompany%2Fukasko" target="_blank" rel="nofollow"><i class="linkedin"></i></a>
            </div>
         </div>
         <div v-if="! isPartner()" class="footer-agreement col-12 text-center">
            <a class="text-center"  href="/pdf/contract.pdf" target="_blank" >Договір  оферти</a>
          </div>
      </div>
   </div>
   <div class="footer__payment">
      <div class="container">
         <div class="footer__payment__list">
            <p>Можна оплатити за допомогою:</p>
            <img src="https://ukasko.ua/images/payments/visa.svg" alt="visa"> <img src="https://ukasko.ua/images/payments/mastercard.svg" alt="mastercard"> <img src="https://ukasko.ua/images/payments/liqpay.svg" alt="liqpay"> <img src="https://ukasko.ua/images/payments/wayforpay.png" alt="wayforpay">
         </div>
      </div>
   </div>
</footer>
</template>

<script>
import {userMixin} from '@/mixin';

export default {
    mixins: [userMixin],
    computed: {
        checkSpecialLogo: function () {
            //31 -user id
            return this.$store.getters.USER?.id === 31 || this.$store.getters.USER?.user_parent_id === 31;
        },
    }
}
</script>
