export default {
    data() {
        return {

        };
    },
    computed: {
        partnersList: function () {
            return this.$store.getters.ALL_PARTNERS;
        },
    },
    mounted() {
        if (! this.partnersList) {
            this.fetchPartnersList();
        }
    },
    /**
     *
     * @returns {*}
     */
    serverPrefetch () {
        this.fetchPartnersList();
    },
    methods: {
        fetchPartnersList() {
            return this.$store.dispatch('GET_ALL_PARTNERS');
        }
    }
}
