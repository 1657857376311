const state = {
  types: [
    { name: 'Всі типи', id: null },
    { name: 'Фіз.особа', id: 1 },
    { name: 'Юр.особа', id: 2 },
    { name: 'Пільговик', id: 3 },
  ],
};

const getters = {
    USER_TYPES_TARIFF_LIST: state => state.types
};

export default {
    state,
    getters
};

