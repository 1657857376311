<script>
import UserMenu from "@/components/app/profile/userMenu.vue";
import UserHeader from "@/components/app/profile/userHeader.vue";

export default {
    name: "userPopover",
    components: {UserHeader, UserMenu},
    data() {
        return {
            showPopover: false
        }
    },
}
</script>

<template>
    <div class="mr-5">
        <el-popover
            placement="bottom"
            width="300"
            trigger="click"
            v-model="showPopover"
        >
            <div slot="reference">
                <el-row type="flex" align="middle" justify="center" class="pointer">
                    <el-col :span="23">
                        <user-header></user-header>
                    </el-col>
                    <el-col :span="1">
                        <div class="m-2">
                            <i class="el-icon-arrow-up" v-if="showPopover"></i>
                            <i class="el-icon-arrow-down" v-else></i>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <user-menu></user-menu>
        </el-popover>
    </div>
</template>

<style scoped>
.pointer {
    cursor: pointer;
}
</style>
