const state = {
    mobileWith: 992,
    isMobile: false,
}

const mutations = {
    SET_IS_MOBILE(state, payload) {
        state.isMobile = payload
    }
}

const actions = {
    checkIsMobile({ commit }) {
        commit('SET_IS_MOBILE', window.innerWidth < state.mobileWith)
    }
}

export default {
    // namespaced: true,
    state,
    actions,
    mutations
};
