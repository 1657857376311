const state = {
    types: [
        { name: 'Всі типи', id: null },
        { name: '0', id: 0 },
        { name: '1000', id: 1000 },
        { name: '1300', id: 1300 },
        { name: '1500', id: 1500 },
        { name: '1600', id: 1600 },
        { name: '2000', id: 2000 },
        { name: '2400', id: 2400 },
        { name: '2500', id: 2500 },
        { name: '2600', id: 2600 },
        { name: '3000', id: 3000 },
        { name: '3200', id: 3200 },
    ],
};

const getters = {
    FRANCHISE_LIST: state => state.types
};

export default {
    state,
    getters
};
