export default {
    data: function () {
        return {

        };
    },
    created: async function () {

    },
    computed: {
        clientStatuses: function () {
            return this.$store.getters.CLIENTS_STATUSES;
        },
        clientOrdersSubStatuses: function () {
            return this.$store.getters.CLIENTS_ORDERS_SUB_STATUSES;
        },
        allInfo: function () {
            return this.$_.findWhere(this.clientStatuses, { type: 'all'})?.id;
        },
        allSubInfo: function () {
            return this.$_.findWhere(this.clientOrdersSubStatuses, { type: 'all'})?.id;
        },
        activeSubInfo: function () {
            return this.$_.findWhere(this.clientOrdersSubStatuses, { type: 'active_orders'})?.id;
        },
        ordersList: function () {
            return this.$_.findWhere(this.clientStatuses, { type: 'orders'})?.id;
        },
        objectsList: function () {
            return this.$_.findWhere(this.clientStatuses, { type: 'objects' })?.id;
        },
        documentsLists: function () {
            return this.$_.findWhere(this.clientStatuses, { type: 'documents'})?.id;
        },
    }
}
