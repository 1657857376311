import {checkOtp, sendOtp} from "@/api/order";

export default {
    methods: {
        formSendOtp(){
            this.$refs.ChoiceOtpSendingDirection.open();
        },
        otpChenged(){
            this.otpFlags.sendingOtpError = false;
            this.otpFlags.submitOtpCheck = false;
            this.otpFlags.otpCodeValidate = true;
        },
        async otpCheck(otpPass){
            this.otpFlags.submitOtpCheck = true;
            if(!otpPass) return;
            this.otpFlags.pandingOtpCheck = true;
            let resCheckOtp = await checkOtp(this.orderId, otpPass).catch(error =>  {});
            const resCheckOtpStatus = resCheckOtp?.data?.message ?? null;
            if(resCheckOtpStatus !== "OK"){
                this.otpFlags.otpCodeValidate = false;
            }else{
                this.otpFlags.otpCodeValidate = true;
                this.otpFlags.confirmOtpStatus = true;
            }
            this.otpFlags.pandingOtpCheck = false;
        },
        async sendOtpApi(type){
            this.otpFlags.sentOtpPass = true;
            this.otpPass = "";
            this.otpFlags.pandingOtpSending = true;
            let sendOtpRes = await sendOtp(this.orderId, type);
            const sendOtpStatus = sendOtpRes?.data?.message ?? null;
            if(sendOtpStatus !== "OK"){
                this.otpFlags.sendingOtpError = true;
            }else{
                this.otpFlags.sendingOtpError = false;
            }
            this.otpFlags.pandingOtpSending = false;
        },
    }
}
