<template>
    <div class="profile-layout">
        <headerMain layout="profile" />
        <main class="cabinet">
            <div class="user profile">
                <navbar/>
                <div class="router-section">
                    <router-view />
                </div>
            </div>
        </main>
        <modalSearch/>
        
        <footerMain/>
    </div>
</template>

<script>
import navbar from '@/components/app/profile/navbar';
import headerMain from '@/components/app/headerMain';
import footerMain from '@/components/app/footerMain';
import modalSearch from '@/components/app/modals/modal-search';

export default {
    name: 'profile-layout',
    components:{
        navbar, headerMain, footerMain, modalSearch
    }
}
</script>
