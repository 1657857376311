const state = {
    registrationType: [
        { value: 'Постійна реєстрація', id: 1, name: 'permanentRegistration' },
        { value: 'Тимчасова реєстрація', id: 2,  name: 'temporaryRegistration' },
        //{ value: 'Тимчасовий в\'їзд', id: 3,  name: 'temporaryEntry'},
    ],
};

const getters = {
    INSURANCE_REGISTRATION_TYPE: state => state.registrationType,
};

export default {
    state,
    getters
};

