<script>
import CheckPenaltiesModal from "@/components/app/penalties/CheckPenaltiesModal.vue";
import CheckPenaltiesByCarModal from "@/components/app/penalties/CheckPenaltiesByCarModal.vue";
import CheckPenaltiesByProtocolModal from "./CheckPenaltiesByProtocolModal.vue"
import CheckPenaltiesByDriverLicenceModal from "./CheckPenaltiesByDriverLicenceModal.vue"
import PenaltyListModal from "./PenaltyListModal.vue";

export default {
    name: "CheckPenaltiesButton",
    components: {
        CheckPenaltiesByCarModal,
        CheckPenaltiesModal,
        CheckPenaltiesByProtocolModal,
        CheckPenaltiesByDriverLicenceModal,
        PenaltyListModal
    },
    data() {
        return {
            visibleModal: false,
            visibleCarModal: false,
            penalties: [],
            componentModal: 'CheckPenaltiesModal',
            // componentModal: 'PenaltyListModal'
        }
    },
    methods: {
        changeModalTo(modal) {
            this.componentModal = modal;
        }
    },
}
</script>

<template>
    <div>
        <div @click="visibleModal = !visibleModal">
            <slot>
                <!--@click="visibleModal = !visibleModal"-->
                <button class="btn btn-primary p-2" style="background-color: #999DA6">
                    <img class="pr-2" src="~@/assets/images/icons/check-penalty.svg" width="22" height="22">
                    Перевірити штрафи
                </button>
            </slot>
        </div>

        <component
            :is="componentModal"
            :visible.sync="visibleModal"
            :penalties.sync="penalties"
            @change-modal="changeModalTo($event)"
        />
    </div>
</template>

<style scoped>

</style>
