import penaltyApi from "@/api/penalty";

export default {
    methods: {
        payPenalty(penalty) {
            penaltyApi.payPenalty(penalty.docId).then((paymentResponse) => {
                this.payment = paymentResponse.data
                location.href = this.payment.source.link;
            })
        }
    }
}
